import React, { useEffect, useState } from "react";
import {
  ReadOne,
  Update,
  Create,
  ReadCategoryList,
  HandleVisibility,
} from "../api";
import styles from "../styles/BlogViewPage.module.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import Delta from "quill-delta";

Quill.register("modules/imageResize", ImageResize);
const ClipboardModule = Quill.import("modules/clipboard");
const fileReader = new FileReader();

class CustomClipboard extends ClipboardModule {
  onPaste(event) {
    const range = this.quill.getSelection();
    const formats = this.quill.getFormat(range.index);
    const pastedDelta = this.convert(formats);
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .concat(pastedDelta);
    this.quill.updateContents(delta, "silent");
    // range.length contributes to delta.length()
    this.quill.setSelection(
      delta.length() - range.length,
      range.length,
      "silent"
    );
    this.quill.scrollIntoView();
  }
}

Quill.register("modules/clipboard", CustomClipboard, true);

function BlogViewPage() {
  // avoid page reload to save data
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "Are you sure you want to leave this page?";
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const navigate = useNavigate();
  const [serverMessage, setServerMessage] = useState("");
  const params = useParams();

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [headerImage, setHeaderImage] = useState({});
  const [headerImageURL, setHeaderImageURL] = useState("");
  const [headerImageBlob, setHeaderImageBlob] = useState("");
  const [slug, setSlug] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [contentData, setContentData] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [authorName, setAuthorName] = useState("");
  const [authorSocialLink, setAuthorSocialLink] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");

  // Thumbnail handler
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setHeaderImage(file);  // Save the file to state

      // Convert to base64 when the file is selected
      const reader = new FileReader();
      reader.onloadend = () => {
        // Store the base64 string in the state
        setHeaderImageBlob(reader.result);
      };
      reader.readAsDataURL(file);  // Read the file as a base64 string
    }
  };

  const getCategories = async () => {
    let data = await ReadCategoryList(token, "blog");
    if (data.name) {
      setCategoryList([]);
    } else {
      setCategoryList(data);
    }
  };

  const fetchData = async (id) => {
    setIsLoading(true);
    let data = await ReadOne(id, token, "blog");

    setId(data?._id);
    setTitle(data?.title);
    setMetadesc(data?.metadesc);
    setHeaderImageURL(data?.header_image);
    setSlug(data?.slug);
    setContentData(data?.content);
    setCategory(data?.category);
    setIsActive(data?.isActive);
    setKeywords(data?.keywords);
    setAuthorName(data?.author?.name || "Nitesh Jain");
    setAuthorSocialLink(data?.author?.socialLink || "https://www.linkedin.com/in/initeshjain");
    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      if (params.id !== "create-new") {
        fetchData(params.id);
        getCategories();
      } else {
        getCategories();
        setAuthorName("Nitesh Jain");
        setAuthorSocialLink("https://www.linkedin.com/in/initeshjain");
      }
    }
  }, []);

  // const deleteBlog = async (id) => {
  //   let confirmDelete = confirm("Do you really want to delete the blog?");
  //   if (confirmDelete) {
  //     let response = await Delete(id, token, "blog");
  //     setServerMessage(response.message);
  //     navigate("/blogs");
  //   }
  // };

  const handleVisibility = async (id, isActive) => {
    let what = isActive ? "deactivate" : "activate";
    // eslint-disable-next-line no-restricted-globals
    let confirmChange = confirm(`Do you really want to ${what} the blog?`);
    if (confirmChange) {
      let response = await HandleVisibility(id, token, "blog");
      fetchData();
    }
  };

  const UpdateData = async () => {
    let payload = {
      id,
      metadesc,
      header_image: headerImageBlob,
      header_image_url: headerImageURL,
      content: contentData,
      slug,
      title,
      category,
      keywords,
      author: {
        name: authorName,
        socialLink: authorSocialLink
      }
    };

    let response = await Update(payload, token, "blog");
    setServerMessage(response.message);
    navigate(`/blog/${id}`);
  };

  const AddNew = async () => {
    let payload = {
      id,
      metadesc,
      header_image: headerImageBlob,
      header_image_url: headerImageURL,
      content: contentData,
      slug,
      title,
      category,
      keywords,
      author: {
        name: authorName,
        socialLink: authorSocialLink
      }
    };

    let response = await Create(payload, token, "blog");
    setServerMessage(response.message);
    navigate(`/blog/${response._doc._id}`);
  };

  const Process = () => {
    if (params.id !== "create-new") {
      UpdateData();
    } else {
      AddNew();
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { list: 'check' },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }], // Font and background color options
      [{ font: [] }], // Font family options
      [{ align: [] }], // Alignment options
      [{ 'script': 'sub' }, { 'script': 'super' }],
      ["link", "image", "omega"],
      ["clean"],
      ["code-block"],
      [{ 'direction': 'rtl' }],
    ],
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "omega",
    "image",
    "code-block",
    "color",
    "font",
    "background",
    "align",
    "script",
    "direction"
  ];

  const quillRef = React.createRef();

  // Register a custom image handler to embed image links
  useEffect(() => {
    const quill = quillRef.current.getEditor();

    // (omega) image URL inserter
    const customButton = document.querySelector('.ql-omega');

    const handleClick = () => {
      const url = prompt("Enter the URL of the image:");
      if (url) {
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", url, Quill.sources.USER);
      }
    };

    // Remove any existing click event listeners to avoid duplicates
    customButton?.removeEventListener('click', handleClick);

    customButton?.addEventListener('click', handleClick);

    // Add SVG for new (omega) image URL inserter
    customButton.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
      <path d="M2 6.342a3.375 3.375 0 0 1 6-2.088 3.375 3.375 0 0 1 5.997 2.26c-.063 2.134-1.618 3.76-2.955 4.784a14.437 14.437 0 0 1-2.676 1.61c-.02.01-.038.017-.05.022l-.014.006-.004.002h-.002a.75.75 0 0 1-.592.001h-.002l-.004-.003-.015-.006a5.528 5.528 0 0 1-.232-.107 14.395 14.395 0 0 1-2.535-1.557C3.564 10.22 1.999 8.558 1.999 6.38L2 6.342Z" />
    </svg>
  `;

    return () => {
      // Clean up the event listener when the component unmounts
      customButton?.removeEventListener('click', handleClick);
    };
  }, []);


  return (
    <div className={styles.main_div}>
      {/* <pre>{JSON.stringify(blog, null, 2)}</pre> */}
      {!token ? (
        <div>PLEASE LOG IN</div>
      ) : (
        <>
          <div className={styles.message}>
            <p>server message: {serverMessage}</p>
          </div>

          <h5>Title</h5>
          <input
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <h5>Content</h5>
          <ReactQuill
            ref={quillRef}
            name="content"
            theme="snow"
            value={contentData}
            onChange={setContentData}
            modules={modules}
            formats={formats}
            className={styles.editorInput}
          />

          <h5>Slug</h5>
          <input
            type="text"
            name="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />

          <h5>Header Image URL</h5>
          <input
            type="url"
            name="header_image_url"
            value={headerImageURL}
            onChange={(e) => setHeaderImageURL(e.target.value)}
          />

          <h5>Header Image</h5>
          <input
            type="file"
            name="header_image"
            accept="image/*"
            onChange={(e) => handleFileChange(e)}
          />

          <h5>Metadesc</h5>
          <textarea
            type="text"
            name="metadesc"
            value={metadesc}
            onChange={(e) => setMetadesc(e.target.value)}
          />

          <h5>Keywords (comma separated values)</h5>
          <textarea
            type="text"
            name="keywords"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />

          <h5>Category</h5>
          <input
            type="text"
            name="category"
            value={category}
            list="categorydatalist"
            onChange={(e) => setCategory(e.target.value)}
          />

          <datalist id="categorydatalist">
            {categoryList.length !== 0 &&
              categoryList.map((cat, i) => {
                return (
                  <option key={i} value={cat}>
                    {cat}
                  </option>
                );
              })}
          </datalist>

          <h5>Author Name</h5>
          <input
            type="text"
            name="author_name"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
          />

          <h5>Author Social Link</h5>
          <input
            type="text"
            name="author_social_link"
            value={authorSocialLink}
            onChange={(e) => setAuthorSocialLink(e.target.value)}
          />

          <div className={styles.btns}>
            <button
              className={[styles.btn, styles.del_btn].join(" ")}
              onClick={() => handleVisibility(id, isActive)}
            >
              {isActive ? "Deactivate" : "Activate"}
            </button>

            <button
              className={[styles.btn, styles.view_btn].join(" ")}
              onClick={() => Process()}
            >
              Save
            </button>
            {isLoading && <span>Loading...</span>}
          </div>
        </>
      )}
    </div>
  );
}

export default BlogViewPage;
